import React from 'react';

import './video.scss';

function Video(props) {
  const { video, classes } = props;

  console.log('video: ', video);

  return (
    <video key={video} className={`${'video'} ${classes}`} autoPlay muted loop playsInline>
      <source src={video} type="video/mp4" />
    </video>
  );
}

export default Video;
