import React, { useEffect, useCallback } from 'react';
import { CSSTransition } from "react-transition-group";

import './modal.scss';

function Modal(props) {
  const { closeModal } = props;

  const keyBindings = useCallback((event) => {
    if (event.keyCode === 27) {
      closeModal();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', keyBindings, false);
  }, []);

  return (
    <CSSTransition appear in
            classNames="modal-transition"
            unmountOnExit
            timeout={ 300 }>
      <div className="modal-wrapper">
        <div className="modal-content">
          <div className="modal-content__top">
            <div className="modal-content__top--header">
              <h1>Overview</h1>
            </div>

            <div className="modal-content__top--close" onClick={ closeModal }>
              x
            </div>
          </div>

          <div className="modal-content__bottom">
            <h1>
              A poetic meditation on our proximity to nature through biomes of technonatural expression. Made by <a href="https://gabyyy.com/" target="_blank" className="underline" rel="noreferrer">Gaby Re</a> & <a href="https://jameslamarre.com/" target="_blank" className="underline" rel="noreferrer">James La Marre</a>.
            </h1>
            <p>
              Against the threat of the virus and climate change, nature, in its diversity and beauty, offers the promise of regeneration and kinship. Yet we're confined by the pandemic to the immediate boundaries of our home, increasingly reliant on digital technology and surrounded by self-teaching algorithms engineered to maximize screen time and demand more attention, continuously sensing and manipulating our emotional state with addictive UI patterns.
            </p>
            <p>
              In this new reality of ubiquitous connectivity where unbridled digital consumption has us feeling mentally wiped out, anxious and isolated, how might digital tools help us remediate our relationship with earth? Can technology be in symbiosis with nature? Can we subvert algorithms to facilitate mindfulness, empathy, and care instead?
            </p>
            <p>
              Alternatively, digital tools can be viewed from a critically empathic lens, especially given these tool’s origins and their more prevalent harmful application in areas such as subjugation and surveillance. While it can be easy to let this criticality override the positive and servicing functions digital tools have provided for disenfranchised communities in lockdown, providing schematics for the wonderful possibilities of these tools  for humanity and society overall.
            </p>
            <p>
              Cardinal is an ode to the power of flowers, their seed-charge, their fragility and resilience, their permanence, their colorful and uncolored side, an embodiment and sensorial exploration in the uncertainties of our present moment.
            </p>
          </div>

          <div className="modal-content__top">
            <div className="modal-content__top--header">
              <h1>How It Works</h1>
            </div>
          </div>

          <div className="modal-content__bottom">
            <p>
              Every instance a user hits the page, we're running a python script on the hosting server to generate a json digested through a React frontend to display on the site. The python script joins natural language processing of source texts with an image captioning neural network to produce unique captions based on the speculative flower image using only language from the learned process. The source text comes from three primary sources: a Poetry Foundation dataset, a climate change sentiment collection from Twitter, and Wild Flowers Worth Knowing by Neltje Blanchan.
            </p>
            <p>
              Each cardinal point corresponds to a biome’s catalog of flora (East/Air, North/Fire, West/Water, South/Earth). Black & white photography was captured on Ilford SFX200 infrared 35mm film, in 2019, at the following locations: Grosseto, Italy; Tikal, Guatemala; Woodstock, NY and East Hampton, NY.
            </p>
            <p>
              The series of video sculptures featured in each biome are generated by neural networks, programmed to spawn a novel speculative flower species based on a huge flora dataset. Then digitally manipulated to add distortions and glitches, a truly synthetic hybrid to offer a counternarrative to their historical belonging, forming a tension between reality and its representation.
            </p>
          </div>

          <div className="modal-content__top">
            <div className="modal-content__top--header">
              <h1>Credits</h1>
            </div>
          </div>

          <div className="modal-content__bottom">
            <p>
              Creative Direction, Design + Photography by <a href="https://gabyyy.com/" target="_blank" className="underline" rel="noreferrer">Gaby Re</a>
            </p>
            <p>
              Development by <a href="https://jameslamarre.com/" target="_blank" className="underline" rel="noreferrer">James La Marre</a>.
            </p>
            <p>
              Overview by <a href="https://agoldencalf.com/" target="_blank" className="underline" rel="noreferrer">Jason Scott Rosen</a>.
            </p>
            <p>
              Sound Design by <a href="https://soundcloud.com/mikeswells" target="_blank" className="underline" rel="noreferrer">Mike Swells</a>.
            </p>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}

export default Modal;
