import React from 'react';
import { CSSTransition } from 'react-transition-group';

import Image from '../image/Image';

import { ReactComponent as PlusSVG } from '../../assets/svgs/plus.svg'
import { ReactComponent as TopographySVG } from '../../assets/svgs/topography/melted-topography-green-outlines.svg'

import './controls.scss';

function Controls(props) {
  const { env, background, changeEnv, closeEnv, changeBackground, image, webgl } = props;
  const viewerVisible = env === "landing" ? true : false;

  const viewer = <CSSTransition
                    classNames="viewer-transition"
                    in={viewerVisible}
                    timeout={ 6000 }
                    unmountOnExit>
                    <div className={`viewer-wrapper`}>
                      <div className={`viewer ${ background } ${ background !== 'landing' ? 'active' : '' }`}>
                        <Image src={ image } classes="viewer-image" alt="" />
                        <div ref={ webgl } className={` viewer__canvas-wrapper `}></div>
                      </div>
                    </div>
                  </CSSTransition>;

  const backButton = <div className="back-button" onClick={ closeEnv }>
                  <p className="controls-copy">back</p>
                </div>;

  return (
    <>
      <div className={`plus-wrapper ${ background } ${env === "landing" ? 'plus-wrapper--shorten' : 'hide'}`}>
        <PlusSVG />
        <CSSTransition
          classNames="topography-transition"
          in={ viewerVisible }
          timeout={ 6000 }
          unmountOnExit>
          <div className={`topography animate-topography-enter ${ background !== 'landing' ? background : ''}`}>
            <TopographySVG />
          </div>
        </CSSTransition>;

      </div>

      { viewer }

      <CSSTransition
        classNames="controls-transition"
        in
        appear
        unmountOnExit
        timeout={1500}>
        <div className={`controls-wrapper`}>
          <div tabIndex="0" className={`env-link env-link--north ${env === 'north' ? 'active' : env === 'landing' ? '' : 'hide'}`}
               env={ "north" } onClick={ changeEnv } onMouseOver={ changeBackground }>
            Galla
          </div>
          <div tabIndex="0" className={`env-link env-link--south ${env === 'south' ? 'active' : env === 'landing' ? '' : 'hide'}`}
               env={ "south" } onClick={ changeEnv } onMouseOver={ changeBackground }>
            Phorae
          </div>
          <div tabIndex="0" className={`env-link env-link--east ${env === 'east' ? 'active' : env === 'landing' ? '' : 'hide'}`}
               env={ "east" } onClick={ changeEnv } onMouseOver={ changeBackground }>
            Seedge
          </div>
          <div tabIndex="0" className={`env-link env-link--west ${env === 'west' ? 'active' : env === 'landing' ? '' : 'hide'}`}
               env={ "west" } onClick={ changeEnv } onMouseOver={ changeBackground }>
            Tvanna
          </div>
        </div>
      </CSSTransition>

      {
        env !== "landing" ? backButton : ''
      }
    </>
  );
}

export default Controls;
