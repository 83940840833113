import React, { useState } from 'react';
import Lottie from 'react-lottie';

import * as animationData from './cardinal-logo.json'
import * as mobileData from './cardinal-logo-mobile.json'

import './nav.scss';

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const mobileOptions = {
  loop: false,
  autoplay: true,
  animationData: mobileData.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

function Nav(props) {
  const [isStopped, setIsStopped] = useState(true);
  const { closeEnv } = props;

  return (
    <div className="top-nav">
      <div className="nav-link nav-link--desktop"
            onClick={ closeEnv }
            onMouseEnter={() => setIsStopped(false)}
            onMouseLeave={() => setIsStopped(true)}>
        <Lottie options={ defaultOptions }
                height={"100%"}
                width={"100%"}
                isStopped={isStopped} />
      </div>
      <div className="nav-link nav-link--mobile"
            onClick={ closeEnv }>
        <Lottie options={ mobileOptions }
                height={"100%"}
                width={"100%"}
                isStopped={isStopped} />
      </div>
    </div>
  );
}

export default Nav;
