import React from 'react';

import './image.scss';

function Image(props) {
  const { src, alt, classes } = props;
  // const image = require(`./../../assets/images/${ src }`);

  // console.log(src);
  // console.log(image);

  return (
    <figure className={`${classes}`}>
      <img src={ src } className={`${'image'}`} alt={ alt } />
    </figure>
  );
}

export default Image;
