import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import { ReactComponent as PlusSVG } from '../../assets/svgs/plus.svg'

import Video from '../video/Video';

import northVideo1 from '../../assets/gan/north/01_north_flower_a2.mp4';
import northVideo2 from '../../assets/gan/north/01_north_flower_b2.mp4';
import northVideo3 from '../../assets/gan/north/01_north_flower_c2.mp4';

import southVideo1 from '../../assets/gan/south/02_south_flower_a2.mp4';
import southVideo2 from '../../assets/gan/south/02_south_flower_b2.mp4';
import southVideo3 from '../../assets/gan/south/02_south_flower_c2.mp4';

import eastVideo1 from '../../assets/gan/east/04_east_flower_a2.mp4';
import eastVideo2 from '../../assets/gan/east/04_east_flower_b2.mp4';
import eastVideo3 from '../../assets/gan/east/04_east_flower_c2.mp4';

import westVideo1 from '../../assets/gan/west/03_west_flower_A2.mp4';
import westVideo2 from '../../assets/gan/west/03_west_flower_b2.mp4';
import westVideo3 from '../../assets/gan/west/03_west_flower_c2.mp4';

import textSource from '../../assets/text/source_text.json';

import './environment.scss';

function Environment(props) {
  const { env, showEnv } = props;
  const [video, setVideo] = useState(0);
  const [animate, setAnimate] = useState(false);
  const northVideos = [northVideo1, northVideo2, northVideo3];
  const southVideos = [southVideo1, southVideo2, southVideo3];
  const eastVideos = [eastVideo1, eastVideo2, eastVideo3];
  const westVideos = [westVideo1, westVideo2, westVideo3];

  useEffect(() => {
    if (!document.querySelector('.image-container')) return;
    document.querySelector('.image-container').classList.add('animate');
    setTimeout(function() {
      document.querySelector('.image-container').classList.remove('animate');
    }, 1000);

    document.querySelector('.text-container').classList.add('animate');
    setTimeout(function() {
      document.querySelector('.text-container').classList.remove('animate');
    }, 1000);
  }, [video]);

  const getVideoSet = () => {
    switch(env) {
      case 'north':
        return northVideos;
      case 'south':
        return southVideos;
      case 'east':
        return eastVideos;
      case 'west':
        return westVideos;
      default:
        return northVideos;
    }
  }

  const videos = getVideoSet();

  const selector = (i) => {
    return (
      <div className={`selector ${i === video ? 'active' : ''}`}
            key={ i }
            onClick={() => setVideo(i) } />
    )
  }

  return (
    <CSSTransition
      classNames="environment-transition"
      in={ showEnv }
      appear
      unmountOnExit
      timeout={ 550 }>
      <div className={`environment-container ${ env }`}>

        <div className={`plus-wrapper plus-wrapper--environment plus-wrapper--no-animation ${ env }`}>
          <PlusSVG />
        </div>

        <div className={`${env !== "landing" ? 'environment' : ''} controls-wrapper no-hover`}>
          <div className={`env-link env-link--north ${env === 'north' ? 'active' : 'hide'}`}>
            Galla
          </div>
          <div className={`env-link env-link--south ${env === 'south' ? 'active' : 'hide'}`}>
            Phorae
          </div>
          <div className={`env-link env-link--east ${env === 'east' ? 'active' : 'hide'}`}>
            Seedge
          </div>
          <div className={`env-link env-link--west ${env === 'west' ? 'active' : 'hide'}`}>
            Tvanna
          </div>
        </div>

        <div className="environment-wrapper">
          <div className={`image-container ${ animate ? 'animate' : '' }`} onAnimationEnd={() => setAnimate(false)}>
            <Video video={ videos[video] } classes={''} />
          </div>

          <div className="text-container">
            <p className="text-container__line">{ textSource[Math.floor(Math.random() * 30000 + 1)] }</p>
            <p className="text-container__line">{ textSource[Math.floor(Math.random() * 30000 + 1)] }</p>
            <p className="text-container__line">{ textSource[Math.floor(Math.random() * 30000 + 1)] }</p>
          </div>

          <div className="selectors-wrapper">
            {videos.map((s, i) => {
              return selector(i)
            })}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}

export default Environment;
