import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Landing from "./views/container/Container";

import './styles/global.scss';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <div className="content-wrapper">
        <Route path="/" exact component={ Landing } />
      </div>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
