import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';

import Nav from '../../components/nav/Nav';
import Shop from '../../components/shop/Shop';
import Modal from '../../components/modal/Modal';
import Controls from '../../components/controls/Controls';
import Environment from '../../components/environment/Environment';

import ni1 from '../../assets/images/flowers/north/bw_photos_tuscany-1.jpg';
import ni2 from '../../assets/images/flowers/north/bw_photos_tuscany-2.jpg';
import ni3 from '../../assets/images/flowers/north/bw_photos_tuscany-3.jpg';
import ni4 from '../../assets/images/flowers/north/bw_photos_tuscany-4.jpg';

import si1 from '../../assets/images/flowers/south/bw_photos_tikal-1.jpg';
import si2 from '../../assets/images/flowers/south/bw_photos_tikal-2.jpg';
import si3 from '../../assets/images/flowers/south/bw_photos_tikal-3.jpg';
import si4 from '../../assets/images/flowers/south/bw_photos_tikal-4.jpg';

import ei1 from '../../assets/images/flowers/east/bw_photos_woodstock-1.jpg';
import ei2 from '../../assets/images/flowers/east/bw_photos_woodstock-2.jpg';
import ei3 from '../../assets/images/flowers/east/bw_photos_woodstock-3.jpg';
import ei4 from '../../assets/images/flowers/east/bw_photos_woodstock-4.jpg';

import wi1 from '../../assets/images/flowers/west/bw_photos_hamptons-1.jpg';
import wi2 from '../../assets/images/flowers/west/bw_photos_hamptons-2.jpg';
import wi3 from '../../assets/images/flowers/west/bw_photos_hamptons-3.jpg';
import wi4 from '../../assets/images/flowers/west/bw_photos_hamptons-4.jpg';

import northAudio from '../../assets/audio/North_Fire_final.mp3';
import southAudio from '../../assets/audio/South_Earth_final.mp3';
import eastAudio from '../../assets/audio/East_Air_final.mp3';
import westAudio from '../../assets/audio/West_Water_final.mp3';

// eslint-disable-next-line no-unused-vars
import styles from './container.scss';

function Container(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [env, setEnv] = useState('landing');
  const [showEnv, setShowEnv] = useState(false);
  const [image, setImage] = useState('');
  const [isAnimating, setAnimate] = useState(false);
  const [background, setBackground] = useState('landing');

  const { history } = props;

  const northImages = [ni1, ni2, ni3, ni4];
  const southImages = [si1, si2, si3, si4];
  const eastImages = [ei1, ei2, ei3, ei4];
  const westImages = [wi1, wi2, wi3, wi4];

  const webgl = useRef(null);

  useEffect(() => {
    const { current } = webgl;
    if (!current) return;
    if (!image) return;
    if (!isAnimating) return;

    const getBackgroundColor = (env) => {
      switch(env) {
        case 'north':
          return 0xF7ABC6;
        case 'south':
          return 0x00FA9A;
        case 'east':
          return 0x7995F7;
        case 'west':
          return 0xD3C2F4;
        default:
          return 0xF7ABC6;
      }
    }

    console.log('env: ', env);

    const backgroundColor = getBackgroundColor(env);

    const scene = new THREE.Scene();
    const renderer = new THREE.WebGLRenderer({alpha: true});
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(backgroundColor, 0);
    setTimeout(() => renderer.setClearColor(backgroundColor, 1), 100);

    const light = new THREE.PointLight( 0xffffff, 1, 0 );
    light.position.set(1, 1, 100 );
    scene.add(light)

    const camera = new THREE.OrthographicCamera( -1, 1, 1, -1, 1, 1000);
    camera.position.z = 1;

    const { domElement } = renderer;
    current.appendChild(domElement);

    const getColorTop = (env) => {
      switch(env) {
        case 'north':
          return 0xF7ABC6;
        case 'south':
          return 0xD3C2F4;
        case 'east':
          return 0xD3C2F4;
        case 'west':
          return 0xF7ABC6;
        default:
          return 0xF7ABC6;
      }
    }

    const getColorBottom = (env) => {
      switch(env) {
        case 'north':
          return 0x00FA9A;
        case 'south':
          return 0x52F49D;
        case 'east':
          return 0xB8FF4F;
        case 'west':
          return 0x7995F7;
        default:
          return 0x00FA9A;
      }
    }

    const getColorDissolve = (env) => {
      switch(env) {
        case 'north':
          return 0x7995F7;
        case 'south':
          return 0x62D4FB;
        case 'east':
          return 0xE0A890;
        case 'west':
          return 0xB8FF4F;
        default:
          return 0x7995F7;
      }
    }

    const colorTop = getColorTop(env);
    const colorBottom = getColorBottom(env);
    const dissolveColor = getColorDissolve(env);

    const geometry = new THREE.PlaneGeometry(2, 2);

    // add gradient layer
    let frag;
    if (env === 'north' || env === 'south') {
      frag = `
        uniform vec3 direction;
        uniform vec3 bottom;
        uniform vec3 top;

        varying vec2 vUv;

        void main() {

          gl_FragColor = vec4(mix(bottom, top, vUv.y), 1.0);
        }
      `
    } else {
      frag = `
        uniform vec3 direction;
        uniform vec3 bottom;
        uniform vec3 top;

        varying vec2 vUv;

        void main() {

          gl_FragColor = vec4(mix(bottom, top, vUv.x), 1.0);
        }
      `
    }
    const gradient = new THREE.ShaderMaterial({
      uniforms: {
        top: { type: 'c', value: new THREE.Color(colorTop) },
        bottom: { type: 'c', value: new THREE.Color(colorBottom) },
      },
      vertexShader: `
        varying vec2 vUv;

        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
        }
      `,
      fragmentShader: frag,
      wireframe: false
    });
    const gradientMesh = new THREE.Mesh(geometry, gradient);

    setTimeout(() => scene.add(gradientMesh), 500);

    // setup the materials
    let count = 0;
    const material = new THREE.ShaderMaterial({
      vertexShader: `precision mediump float;

                      uniform mat4 matrix_model;
                      uniform mat4 matrix_viewProjection;

                      varying vec2 vUv;

                      void main(void) {
                        vUv = uv;
                        vec3 pos = position;
                        gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
                      }
                `,
      fragmentShader: `varying vec2 vUv;

                        uniform sampler2D uDiffuseMap;
                        uniform sampler2D uHeightMap;

                        uniform float uTime;
                        uniform vec3 dissolveColor;

                        void main(void) {
                          float height = texture2D(uHeightMap, vUv).r;
                          vec4 color = texture2D(uDiffuseMap, vUv);

                          if (height < uTime) {
                            discard;
                          }
                          if (height < (uTime+0.04)) {
                            color = vec4(dissolveColor, 1.0);
                          }
                          gl_FragColor = color;
                        }
                    `,
      uniforms: {
        uTime: { value: 0.0 },
        uHeightMap: { value: new THREE.TextureLoader().load(image) },
        uDiffuseMap: { value: new THREE.TextureLoader().load(image) },
        dissolveColor: { value: new THREE.Color(dissolveColor) },
      },
      transparent: true,
      depthTest: true
    });
    const mesh = new THREE.Mesh(geometry, material);
    mesh.position.set(0,0,0);

    scene.add(mesh);

    const animate = function () {
      if (count / 200 <= 1.4 ) {
        requestAnimationFrame(animate);
        material.uniforms.uTime.value = count / 200;
        count++;
        renderer.clear();

        renderer.render(scene, camera);
      }
    };

    animate();

    return () => {
        // Callback to cleanup three js, cancel animationFrame, etc
        cancelAnimationFrame(animate);

        scene.remove(mesh);
        geometry.dispose();
				material.dispose();
        count = 0;
        current.removeChild(domElement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnimating])

  useEffect(() => {
    const container = document.querySelector('.container');
    if (!container) return;

    container.classList.add('flash');
    setTimeout(function() {
      document.querySelector('.container').classList.remove('flash');
    }, 1000);
  }, [background])

  const getImage = (env) => {
    const random = Math.floor(Math.random() * 4);
    switch(env) {
      case 'north':
        return northImages[random];
      case 'south':
        return southImages[random];
      case 'east':
        return eastImages[random];
      case 'west':
        return westImages[random];
      default:
        return northImages[random];
    }
  }

  const openModal = (e) => {
    setModalOpen(true);
  }

  const closeModal = () => {
    setModalOpen(false);
  }

  const params = new URLSearchParams(window.location.search);

  if (modalOpen === false && params.get('about')) {
    setModalOpen(true);
  }

  const changeEnv = (e) => {
    const newEnv = e.target.getAttribute('env');
    setEnv(newEnv)
    history.push('?' + newEnv);

    setTimeout(() => setAnimate(true), 500);
    setTimeout(() => setAnimate(false), 6000);
    setTimeout(() => setShowEnv(true), 6000);

    let audio;

    switch(newEnv) {
      case 'north':
        audio = new Audio(northAudio);
        break;
      case 'south':
        audio = new Audio(southAudio);
        break;
      case 'east':
        audio = new Audio(eastAudio);
        break;
      case 'west':
        audio = new Audio(westAudio);
        break;
      default:
        audio = new Audio(northAudio);
        break;
    }

    audio.play();
  }

  const closeEnv = () => {
    setShowEnv(false)
    setImage('');
    history.push('/');

    setTimeout(() => setEnv('landing'), 600);
    setTimeout(() => setBackground('landing'), 600);
  }

  const changeBackground = (e) => {
    const backgroundEnv = e.target.getAttribute('env');
    setTimeout(() => setImage(getImage(backgroundEnv)), 400);

    switch(backgroundEnv) {
      case 'north':
        return setBackground('north');
      case 'south':
        return setBackground('south');
      case 'east':
        return setBackground('east');
      case 'west':
        return setBackground('west');
      default:
        return setBackground('landing');
    }
  }

  return (
    <div className={`${"container"} ${background}`}>
      <Nav closeEnv={ closeEnv } />
      <Shop />

      <div className="link-wrapper">
        <div className="nav-link" onClick={ openModal }>
          About
        </div>
      </div>

      {
        modalOpen ? <Modal closeModal={ closeModal } /> : ''
      }

      <Environment showEnv={ showEnv } env={ env } closeEnv={ closeEnv } />
      <Controls changeEnv={ changeEnv }
                closeEnv={ closeEnv }
                background={ background }
                changeBackground={ changeBackground }
                env={ env }
                webgl={ webgl }
                image={ image } />
    </div>
  );
}

export default Container;
